<template>
    <!-- overflow-hidden -->
    <div class="app-inapp-view position-relative">

        <BackgroundAbstract></BackgroundAbstract>
        <!-- шапка -->
        <InappHeader></InappHeader>

        <!-- Преимущества -->
        <CollapseCollection></CollapseCollection>

        <!-- Гео -->
        <GeoSection></GeoSection>

        <!-- Партнеры -->
        <InappSources v-bind:itemsList="partners"></InappSources>

    </div>    
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            partnersHeader: [
                'Our partners',
                'Наши партнеры',
            ],
            block1: {
                title: [
                    'In-app department',
                    'Отдел Ин-апп',
                ],
                subtitle: [
                    'The best decision to promote your app',
                    'Лучшее решение для продвижения приложений',
                ],
                text: [
                    'Increase the number of app users and generate additional income with us. Optimize your advertising budget and explore new perfomance sources',
                    'Увеличивайте базу пользователей и получайте дополнительный доход вместе с нами',
                ],
                textAlign: ' text-right'
            },
            sectionImg1: require('../assets/mobile.png'),
            header: [
                'Explore new traffic sources with us and optimize your spending on your existing ones',
                'Оптимизируйте свой рекламный бюджет и используйте новые, эффективные источники',
            ],
            partners: [
                require('../assets/media/npsources/img1.png'),
                require('../assets/media/npsources/img2.png'),
                require('../assets/media/npsources/img3.png'),
                require('../assets/media/npsources/img4.png'),
                require('../assets/media/npsources/img5.png'),
                require('../assets/media/npsources/img6.png'),
                require('../assets/media/npsources/img7.png'),
                require('../assets/media/npsources/img8.png'),

            ], 
            geoHeader: [
                'GEOs',
                'Географии',
            ],
            geoSubHeader: [
                'worldwide',
                'по всему миру',
            ],            
            geoImage: require('../assets/map.png') 
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX']),
        containerClass: function(){
            return 'px-4 px-xl-0 pb-5 pb-lg-0'
        }
    }
}
</script>

<style lang="sass">
.app-inapp-view
    .app-partner-image
        max-width: 114px
@media (min-width: 414px)        
    .app-inapp-view
        .app-partner-image
            max-width: 140px
@media (min-width: 1023px)        
    .app-inapp-view
        .app-partner-image
            max-width: 178px
@media (min-width: 1366px)        
    .app-inapp-view
        .app-partner-image
            max-width: 224px            
.app-inapp-view
    .app-section-img-1
        width: 100px
.app-inapp-view
    .app-section-img-1
        right: 0
        left: unset
        bottom: -32px
        transform: rotate(6.511deg)
@media (min-width: 1024px)        
    .app-inapp-view
        .app-section-img-1    
            transform: none
            top: 32px
            left: 0
            width: unset
            height: unset
            z-index: 1
@media (min-width: 1366px)                    
    .app-inapp-view
            .app-section-img-1    
                position: static !important
//app-text-block__title                
@media (max-width: 1023px)             
    .app-inapp-view
        .app-text-block__title, .app-text-block__subtitle, .app-text-block__text
            text-align: left !important

@media (min-width: 1366px)                
    .app-collapse-bar
        width: 50%

</style>