<template>
    <div>
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <SimpleHeader v-bind:header="'Our Team'" v-bind:size="'medium'" class="my-5 font-italic"></SimpleHeader>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <SliderCollection></SliderCollection>    
        <!-- <div class="my-5 py-5"></div>
        <div class="my-5 py-5"></div>
        <div class="my-5 py-5"></div> -->
    </div>
</template>

<script>
// import { mapGetters } from 'vuex';

export default {
    computed: {

    }
}
</script>
