<template>
    <div class="app-mediabuying-view position-relative">
        <BackgroundAbstract></BackgroundAbstract>

        <MediabuyingHeader></MediabuyingHeader>

        <AchievementCollection></AchievementCollection>

        <MediabyuingResults v-bind:infoItems="infoItems"></MediabyuingResults>

        <MediaBuyingSources v-bind:itemsList="sources"></MediaBuyingSources>        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            sourceHeader: [
                'Our sources',
                'Наши источники'
            ],
            block1: {
                title: [
                    'Media Buying',
                    'Медиа-баинг',
                ],
                subtitle: [
                    'The right decisions for the best results',
                    // 'Правильные решения для достижения наилучших результатов',
                ], 
                text: [
                    'We work only with high-quality traffic sources that bring the maximum benefit to our sources',
                    'Мы используем только высококачественные источники трафика, которые позволяют максимизировать прибыль наших клиентов и принимать правильные решения для достижения наилучших результатов',
                ], 
                textAlign: ' text-left',
                image: require('../assets/graph.png')
            }, 
            sources: [
                require('../assets/media/mdbsources/img1.png'),
                require('../assets/media/mdbsources/img2.png'),
                require('../assets/media/mdbsources/img3.png'),
                require('../assets/media/mdbsources/img4.png'),
                require('../assets/media/mdbsources/img5.png'),
                require('../assets/media/mdbsources/img6.png'),
                require('../assets/media/mdbsources/img7.png'),
                require('../assets/media/mdbsources/img8.png'),
                require('../assets/media/mdbsources/img9.png'),
                require('../assets/media/mdbsources/img10.png'),


                
            ],
            sourcesCount: 0,  
            infoItems: [
                {
                    title: [
                        '>15', 
                        '>15', 
                    ], 
                    text: [
                        'traffic sources',
                        'Источников трафика'
                    ],
                },
                {
                    title: [
                        '>100m', 
                        '>100м', 
                    ], 
                    text: [
                        'installations',
                        'Установок'
                    ], 
                },
                {
                    title: [
                        '150+', 
                        '150+', 
                    ], 
                    text: [
                        'partners',
                        'Партнеров'
                    ]
                },
            ],                                 
        }
    },
    computed: {
        ...mapGetters(['LANG_INDEX'])        
    },
    methods: {
        itemsCountCorrect: function(num){
            // let emptyItem = require('../assets/media/sources/empty.png');
            //очищаем предыдущие добавлялки
            let max = this.sourcesCount + num
            for (let i=this.sourcesCount; i<max; i++){
                if (this.sources[i]){
                    this.sources.pop()
                }
            }
            //добавляем если надо
            /*
            if (screen.width >=1024 && screen.width <1200){
                for (let i=this.sourcesCount; i<max; i++){
                    this.sources.push(emptyItem)
                }
            }
            */
                return true
        }
    },
    beforeMount: function(){
        //фиксируется единожды
        this.sourcesCount = this.sources.length        
        //вызывается разово
        this.itemsCountCorrect(1)
        let context = this
        //вызывается на ресайзе
        window.onresize = function(){
            context.itemsCountCorrect(1)
        }
    }
}
</script>

<style lang="sass">
.app-section1
    z-index: 2
.app-section2
    z-index: 1
@media (min-width: 992px)
    .app-partner-image.app-source-image 
        max-width: 176px
@media (min-width: 768px)        
    .app-mediabuying-view
        .app-partner-image
            max-width: 130px

@media (min-width: 768px)        
    .app-mediabuying-view
        .app-partner-image
            max-width: 166px    

@media (min-width: 1024px)        
    .app-mediabuying-view
        .app-partner-image
            max-width: 224px
@media (min-width: 1366px)        
    .app-source-image.app-partner-image
        max-width: 188px            
        width: 100%
//костыль
@media (max-width: 767px)            
    .app-source-image
        max-width: 142px !important
//костыль
@media (max-width: 413px)          
    .app-source-image
        max-width: 114px !important      
</style>
