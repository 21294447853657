export default {
    state: {
        navItems: [
            {
                anchor: [
                    'Media Buying', 
                    'Медиа-баинг', 
                ],
                href: '/mediabuying'
            },
            {
                anchor: [
                    'In-app network', 
                    'Отдел Ин-апп', 
                ], 
                href: '/inapp'
            },
            {
                anchor: [
                    'Cases', 
                    'Кейсы', 
                ],
                href: '/cases'
            },
            {
                anchor: [
                    'Team', 
                    'Команда', 
                ],
                href: '/team'
            },            
            {
                anchor: [
                    'Contact us', 
                    'Контакты', 
                ], 
                href: '/contact'
            },
        ],
        socialMedia: [
            {
                name: 'instagram',
                link: '#',
                image: require('../assets/media/social/instagram.png'),
                image1: require('../assets/media/social/instagram1.png')
            },
            {
                name: 'telegram',
                link: '#',
                image: require('../assets/media/social/telegram.png'),
                image1: require('../assets/media/social/telegram1.png')
            },
            {
                name: 'tiktok',
                link: '#',
                image: require('../assets/media/social/tiktok.png'),
                image1: require('../assets/media/social/tiktok1.png')
            },                        
            {
                name: 'linkedin',
                link: '#',
                image: require('../assets/media/social/linkedin.png'),
                image1: require('../assets/media/social/linkedin1.png')
            },            
        ],
        email: {
            name: 'sales@zumoads.com',
            link: 'mailto:sales@zumoads.com'
        },
        teamList: [
            {
                name: 'Разумов Василий',
                position: 'Founder',
                img: require('../assets/media/team/av1.png'),
            },
            {
                name: 'Зубарева Регина',
                position: 'Chief Executive Officer',
                img: require('../assets/media/team/av2.png'),
            },
            {
                name: 'Салимов Аяз',
                position: 'Chief Financial Officer',
                img: require('../assets/media/team/av3.png'),
            },
            {
                name: 'Калчу Александр',
                position: 'Head of Account Management',
                img: require('../assets/media/team/av4.png'),
                contact1: {
                    name: 'kalchu@zumoads.co',
                    link: 'malito:kalchu@zumoads.co',
                },
                contact2: {
                    name: 'skype: live:.cid.8ca1fcd1f9679148',
                    link: 'skype:live:.cid.8ca1fcd1f9679148',
                },                
            },
            {
                name: 'Трихачева Анастасия',
                position: 'Head of affiliates',
                img: require('../assets/media/team/av5.png'),
                contact1: {
                    name: 'anastasia@zumoads.co',
                    link: 'malito:anastasia@zumoads.co',
                },
                contact2: {
                    name: 'skype: live:.cid.e8ea5cfc7d8ac31b',
                    link: 'skype:live:.cid.e8ea5cfc7d8ac31b',
                },                
            },                    
            {
                name: 'Шуваев Милан',
                position: 'Head of Business Development Manager',
                img: require('../assets/media/team/av6.png'),
                contact1: {
                    name: 'Milan@zumoads.co',
                    link: 'malito:Milan@zumoads.co',
                },
                contact2: {
                    name: 'skype: live:.cid.a9506c676c6a7c80',
                    link: 'skype:live:.cid.a9506c676c6a7c80',
                },                
            },                                       
            {
                name: 'Авдияров Никита',
                position: 'Account Manager',
                img: require('../assets/media/team/av7.png'),
                contact1: {
                    name: 'nikitaavdiyarov@zumoads.co',
                    link: 'malito:Milan@zumoads.co',
                },
                contact2: {
                    name: 'skype: live:.cid.9429dfaed6632ead',
                    link: 'skype:live:.cid.9429dfaed6632ead',
                },                
            },            
            {
                name: 'Потапов Илья',
                position: 'Affiliate manager',
                img: require('../assets/media/team/av8.png'),
                contact1: {
                    name: 'ilya@zumoads.co',
                    link: 'malito:ilya@zumoads.co',
                },
                contact2: {
                    name: 'skype: live:.cid.c17b6ca3c710e4ea',
                    link: 'skype:live:.cid.c17b6ca3c710e4ea',
                },                
            },            
            {
                name: 'Сергей Алексеевич',
                position: 'Affiliate manager',
                img: require('../assets/media/team/av9.png'),
                contact1: {
                    name: 'sergey@zumoads.co',
                    link: 'malito:sergey@zumoads.co',
                },
                contact2: {
                    name: 'skype: live:.cid.e0bbea0eeda2d767',
                    link: 'skype:live:.cid.e0bbea0eeda2d767',
                },                
            },            
            {
                name: 'Апсова Лада',
                position: 'Creative Manager',
                img: require('../assets/media/team/av10.png'),
                contact1: {
                    name: 'lada@zumoads.co',
                    link: 'malito:lada@zumoads.co',
                },
                contact2: {
                    name: 'skype: live:.cid.hydradominatus',
                    link: 'skype:live:.cid.hydradominatus',
                },                
            },            
        ]
    },
    getters: {
        TEAM: function(state){
            return state.teamList
        },
        NAV_ITEMS: function(state){
            return state.navItems
        },
        SOCIAL_MEDIA: function(state){
            return state.socialMedia
        },
        EMAIL: function(state){
            return state.email
        }
    }
}