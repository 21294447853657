<template>
  <!-- overflow-hidden -->
  <div class="app-home-view position-relative">
    <BackgroundAbstract></BackgroundAbstract>
    <HomeHeader></HomeHeader>
    <HomeBlock1></HomeBlock1>
    <TotalsCollection 
      v-bind:items="infoItems" 
      v-bind:variant="'variant1'"
      v-bind:background="1"
      class="position-relative"
      style="z-index: 1;"
    ></TotalsCollection>            
    <TeamPhoto style="margin-top: -30px;"></TeamPhoto>
    <section class="pt-4">
      <b-container>
        <b-row>
          <b-col cols="12" lg="6">
            <!--  -->
            <PartnersBlock 
              v-bind:header="clientsHeader[LANG_INDEX]"
              v-bind:headerSize="'medium'"
              v-bind:itemsList="clients"
              v-bind:headerPos="'center'"
              class="mt-3 mt-md-5 mt-xl-0 mb-4 mb-lg-5 px-4 px-lg-5 pt-3 bg-white app-rounded overflow-hidden"
            ></PartnersBlock>
            <!-- v-bind:imageClass="'mx-1 mx-sm-3'" -->
          </b-col>           
          <b-col cols="12" lg="6">
            <!--  -->
            <PartnersBlock 
              v-bind:header="partnersHeader[LANG_INDEX]"
              v-bind:headerSize="'medium'"
              v-bind:itemsList="partners"
              v-bind:headerPos="'center'"
              class="mt-3 mt-md-5 mt-xl-0 mb-4 mb-lg-5 px-4 px-lg-5 pt-3 bg-white app-rounded overflow-hidden"
            ></PartnersBlock>
            <!-- v-bind:imageClass="'mx-1 mx-sm-3'" -->
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data(){
    return {
      bgImage: require('../assets/media/abstraction/abstract1.png'),
      clientsHeader: [
        'Our clients',
        'Наши клиенты',
      ],      
      partnersHeader: [
        'Partners',
        'Партнеры',
      ],
      containerClass: 'px-4 px-xl-0 position-relative',
      block1: {
        title: [
          'Agency that boosts apps like no one else',
          'Агентство, которое повышает узнаваемость и прибыль вашего приложения, как никто другой'
        ],
        textAlign: ' text-left'        
      },
      block2: {
        title: [
          'We help businesses to find new customers',
          'Мы помогаем компаниям находить правильных пользователей',
        ],
        textAlign: ' text-right'
      },
      block3: {
        subtitle: [
          'Technological mobile marketing agency',
          'Технологическое агентство мобильного маркетинга',
        ], 
        text: [
          '5 years of market expertise helps us dive into the project to develop and implement a comprehensive strategy for you to attract new users',
          '5 лет опыта работы на рынке помогают нам погрузиться в проект по разработке и реализации комплексной стратегии для привлечения новых пользователей',
        ], 
        textAlign: ' text-left'
      },      
      sectionImg1: require('../assets/iPhone14.png'),
      sectionImg2: require('../assets/man.png'),
      clients: [
        //винк
        require('../assets/media/clients/client1.png'),
        //пат-пат
        require('../assets/media/clients/client7.png'),        
        //entain
        require('../assets/media/clients/client111.png'),        
        //stydybay
        require('../assets/media/clients/stydybay.png'),        
        //bwin
        require('../assets/media/clients/bwin.png'),                
        //fonbet
        require('../assets/media/clients/client3.png'),                        
        //888
        require('../assets/media/clients/client5.png'),
        //мамба
        require('../assets/media/clients/client11.png'),        
        
        /*
                //фонбет
        // require('../assets/media/clients/client3.png'),
        //тиньков
        // require('../assets/media/clients/client4.png'),
                //месса
        require('../assets/clients/client6.png'), //картинка странно масштабируется
        //бинанс
        require('../assets/media/clients/client8.png'),
        //коронапай
        require('../assets/media/clients/client9.png'),
        //б-вин
        require('../assets/media/clients/client10.png'),
        //лайт ин зе бокс
        require('../assets/media/clients/client12.png'),
        //ДХГейт
        require('../assets/media/clients/client13.png'), //картинка плохо масштабируется
        //ЕксПро
        require('../assets/media/clients/client144.png'),
        //МБ
        require('../assets/media/clients/client15.png'),
        //Ело-Ело
        require('../assets/media/clients/client16.png'),
        */
      ],
      infoItems: [
        {
          titleIcon: require('../assets/infinity.svg'), 
          text: [
            'mobile experts', 
            'бесконечное количество мобильных экспертов', 
          ], 
          specialClass: ' mob-expert'
        },
        {
          title: [
            '100+',
            '100+',
          ],
           text: [
            'clients',
            'клиентов'
           ]
          },
        {
          title: [
            '150+',
            '150+',
          ], 
          text: [
            'partners',
            'партнеров'
          ] 
        },
      ],
      partners: [
        require('../assets/media/partners/partner3.png'),
        require('../assets/media/partners/partner4.png'),
        require('../assets/media/partners/partner5.png'),
        require('../assets/media/partners/partner6.png'),
        require('../assets/media/partners/tapjoy.png'),
        require('../assets/media/partners/partner10.png'),
        require('../assets/media/partners/partner11.png'), //оригинал изображения не дотягивает до 128px
        require('../assets/media/partners/partner12.png'),
        /*
        require('../assets/media/partners/partner8.png'),
        require('../assets/media/partners/partner9.png'),
        require('../assets/media/partners/partner10.png'), //оригинал изображения не дотягивает до 128px
        require('../assets/media/partners/partner11.png'),
        require('../assets/media/partners/partner12.png'),
        */
      ],
    }
  },
  computed: {
    ...mapGetters(['LANG_INDEX'])
  },
  name: 'HomeView',
  components: {
  }
}
</script>

<style lang="sass">
.app-home-view
  .app-section-img-1
    width: 192px
    height: 262px
    left: -24px
    bottom: -86px
    //хуевая идея, могу обосновать
    // z-index: 1

//костылим  
@media (max-width: 767px)  
  .app-home-view
    .app-text-block-1
      border-radius: 30px 30px 0 0 !important
    .app-text-block-2
      border-radius: 0 0 30px 30px !important
      //сдвигаем текст нижней плашки на ширину картинки
      .app-text-block__text-wrap
        text-align: left !important

@media (min-width: 1024px) 
  .app-home-view       
    .app-section-img-1
      width: 292px  
      height: 399px
      left: unset
      bottom: unset
      top: 0
      right: 0

@media (min-width: 1366px)
  .app-home-view              
    .app-section-img-1
      width: 391px
      height: 534px

.app-section-img-3
    width: 208px
    height: 208px
    right: -30px
    bottom: -60px

@media (min-width: 768px)            
  .app-section-img-3
    width: 350px
    height: 350px
    bottom: unset
    top: 0
    right: -80px
    z-index: 1
  //на планшах это возможно
  // .app-text-block-3
  //   .app-text-block__subtitle, .app-text-block__text
  //     padding-right: 280px

@media (min-width: 1366px)                  
  .app-section-img-3
    right: 0
    top: -78px
  .app-text-block-3
    .app-text-block__subtitle, .app-text-block__text
      padding-right: unset

//инфоблоки     
.app-home-view
  .app-info-block
    width: calc(50% - 12px)

//покостылим
.app-home-view
    .app-info-block.mob-expert
      position: absolute  
      background: #52378D      
@media (max-width: 767px)    
  .app-home-view
    .app-info-block.mob-expert
      right: 20px
      width: 124px
      bottom: -28px      
      .app-info-block__title-icon
        max-width: 80px
  .app-block-collection
    border-radius: 30px 30px 0 0 !important

@media (min-width: 768px)  
  .app-info-block.mob-expert
    bottom: -60px
    left: 192px
    width: 192px

@media (min-width: 1366px)
  .app-home-view
    .app-info-block
      width: calc(33% - 24px)
    .app-info-block.mob-expert
      position: static
      background: rgba(82, 55, 141, 0.50)      

//покостылим
@media (max-width: 767px)                              
  .app-block-decore::before
    content: ''
    position: absolute
    top: 0
    left: 15px
    right: 15px
    height: 50%
    background: rgba(82, 55, 141, 0.30)
  .app-mobile-no-radius
    border-radius: 30px 30px 0 0 !important    



</style>