import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './sass/main.sass'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('SectionAndImage', () => import('./components/SectionAndImage'))
Vue.component('TextBlock', () => import('./components/TextBlock'))
Vue.component('SimpleHeader', () => import('./components/SimpleHeader'))
Vue.component('PartnersBlock', () => import('./components/PartnersBlock'))
Vue.component('TeamPhoto', () => import('./components/TeamPhoto'))

//коллекции
Vue.component('BlockCollection', () => import('./components/collections/BlockCollection'))
Vue.component('TotalsCollection', () => import('./components/collections/TotalsCollection'))
Vue.component('AchievementCollection', () => import('./components/collections/AchievementCollection'))
Vue.component('SourcesCollection', () => import('./components/collections/SourcesCollection'))
Vue.component('CollapseCollection', () => import('./components/collections/CollapseCollection'))


//шапки
Vue.component('HomeHeader', () => import('./components/headers/HomeHeader'))
Vue.component('MediabuyingHeader', () => import('./components/headers/MediabuyingHeader'))
Vue.component('InappHeader', () => import('./components/headers/InappHeader'))

//составные блоки
Vue.component('HomeBlock1', () => import('./components/blocks/HomeBlock1'))
Vue.component('MediaBuyingSources', () => import('./components/blocks/MediaBuyingSources'))
Vue.component('MediabyuingResults', () => import('./components/blocks/MediabyuingResults'))
Vue.component('InappSources', () => import('./components/blocks/InappSources'))
Vue.component('GeoSection', () => import('./components/blocks/GeoSection'))
Vue.component('BackgroundAbstract', () => import('./components/blocks/BackgroundAbstract'))

//айтемы
Vue.component('TextCollapse', () => import('./components/items/TextCollapse'))
Vue.component('InfoBlock', () => import('./components/items/InfoBlock'))

//основные
Vue.component('FooterLine', () => import('./components/general/FooterLine'))
Vue.component('NavLine', () => import('./components/general/NavLine'))

Vue.component('AchievementBlock', () => import('./components/items/AchievementBlock'))

//слайдер
Vue.component('SliderCollection', () => import('./components/slider/SliderCollection'))
Vue.component('SliderItem', () => import('./components/slider/SliderItem'))

Vue.component('NavItemDesktop', () => import('./components/NavItemDesktop'))
Vue.component('FormInput', () => import('./components/FormInput'))
Vue.component('FormCanvas', () => import('./components/FormCanvas'))
Vue.component('FormCheckbox', () => import('./components/FormCheckbox'))
Vue.component('SocialMedia', () => import('./components/SocialMedia'))
Vue.component('AwardsCollection', () => import('./components/AwardsCollection'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
